import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

const Index = () => {
  return (
    <Layout>
      <section className="pt-20 md:pt-40">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Software development done right
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              At Evren, we believe that software delivery shouldn't be stressful. Using tried and tested assurance techniques, we
              systematically reduce risk in order to deliver projects reliably and successfully.
            </p>
            <p className="mt-8 md:mt-12">
              <Button size="lg" onClick={() => window.location = "mailto:info@evren.co.uk"}>Get in touch</Button>
            </p>
            <p className="mt-4 text-gray-600">We'll provide you with a detailed proposal free of charge.</p>
          </div>
          <div className="lg:w-1/2">
            <HeroImage />
          </div>
        </div>
      </section>
      <section id="features" className="py-20 lg:pb-40 lg:pt-48">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Our principles</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Reliability</p>
                <p className="mt-4">
                  You can count on us to deliver your product.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Quality</p>
                <p className="mt-4">
                  We take pride in crafting quality software which is easy to maintain in the long term.
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8">
                <p className="font-semibold text-xl">Communication</p>
                <p className="mt-4">
                  We take time to to understand your business needs in order to deliver value.
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <SplitSection
        id="services"
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">IT consultancy</h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Our experts will use their years of experience to help you plan your software journey from day one. They'll see the project 
              through to completion to ensure continuity.
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <SplitSection
        reverseOrder
        primarySlot={
          <div className="lg:pl-32 xl:pl-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Getting things done
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              Our engineers have a proven track record of delivering enterprise grade software. Slow is smooth, smooth is fast.
              We do things right the first time and don't cut corners.
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
      <SplitSection
        primarySlot={
          <div className="lg:pr-32 xl:pr-48">
            <h3 className="text-3xl font-semibold leading-tight">
              Monitor and review
            </h3>
            <p className="mt-8 text-xl font-light leading-relaxed">
              We'll collect metrics and other data to help inform your business decisions. Our iterative and adaptive approach to development means
          that our strategy is informed by regular reviews.
            </p>
          </div>
        }
        secondarySlot={<SvgCharts />}
      />
    {/*
      <section id="stats" className="py-20 lg:pt-32">
        <div className="container mx-auto text-center">
          <LabelText className="text-gray-600">Our customers get results</LabelText>
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
            <div className="w-full sm:w-1/3">
              <StatsBox primaryText="+100%" secondaryText="Stats Information" />
            </div>
            <div className="w-full sm:w-1/3">
              <StatsBox primaryText="+100%" secondaryText="Stats Information" />
            </div>
            <div className="w-full sm:w-1/3">
              <StatsBox primaryText="+100%" secondaryText="Stats Information" />
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials" className="py-20 lg:py-40">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-600 text-center">What our customers are saying</LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {customerData.map(customer => (
              <div key={customer.customerName} className="flex-1 px-3">
                <CustomerCard customer={customer} />
              </div>
            ))}
          </div>
        </div>
      </section>
          */}
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">Ready to grow your business?</h3>
        <p className="mt-8 text-xl font-light">
          We'll manage your technical journey.
        </p>
        <p className="mt-8">
          <Button size="xl" onClick={() => window.location = "mailto:info@evren.co.uk"}>Get in touch</Button>
        </p>
      </section>
    </Layout>
  )
};

export default Index;
